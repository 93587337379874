<template>
	<div id="content">
		<div class="container-fluid">
			<div class="row">
				<div class="col-12">
					<div class="box">
						<DateRange
							:start.sync="start_date"
							:end.sync="end_date"
							@submit="loadPensions"
						/>

						<CustomTable
							ref="pension_table"
							id_table="pension_liste"
							:items="pensions"
							:busy.sync="table_busy"
							primaryKey="horsepension_id"
							:hrefsRoutes="config_table_hrefs"
							:hide_if_empty="true"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
import Navigation from '@/mixins/Navigation.js'
import Pension from '@/mixins/Pension.js'
import Vue from 'vue'

export default {
	name: "PensionListe",
	mixins: [Navigation, Pension],
	data () {
		return {
            pensions: [],

			table_busy: false,
            start_date: new Date(),
            end_date: new Date(new Date().setMonth(new Date().getMonth() + 1)),
			config_table_hrefs: {
				'horsepension_horse.horse_nom': {
					routeUniqueName: 'HorseFichePlanning',
					routeName: 'HorseFichePlanning',
					params: {
						horse_id: 'horsepension_horse.horse_id'
					}
				}
			},
			events_tab: {
				'TableAction::goToPrintPensions': () => {
					this.printPdfPensions(this.start_date, this.end_date)
				}
			},
		}
	},
	created() {
		this.init_component()
	},
	methods: {
		async init_component() {
            this.loadPensions()
		},
        async loadPensions() {
			this.table_busy = true
            let pensions = await this.loadPensionsOnPeriod(this.start_date, this.end_date)
			this.pensions = this.formatPensions(pensions)
			this.table_busy = false
        },
		formatPensions(pensions) {
			let pensionsFormatted = []

			if(!pensions) {
				return []
			}

			let total_days = 0
			for (let i = 0; i < pensions.length; i++) {
				const temp = pensions[i]
				let start_date = this.formatDate(new Date(temp.horsepension_start))
				let end_date = temp.horsepension_end ? this.formatDate(new Date(temp.horsepension_end)) : this.getTrad('pension.unset')

				temp.formatted_period = start_date + ' - ' + end_date
				total_days += temp.horse_pension_days

				pensionsFormatted.push(temp);
			}

			pensionsFormatted.push({
				formatted_name: '   ' + this.getTrad('global.total') + ' (' + total_days + ' ' + this.getTrad('global.days') + ')',
				horse_pension_days: total_days
			});

			return pensionsFormatted
		}
	},
	components: {
		CustomTable : () => import('GroomyRoot/components/Table/CustomTable'),
        DateRange: () => import('GroomyRoot/components/Inputs/DateRange')
	}
}
</script>
